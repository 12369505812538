import PropTypes from "prop-types";
import React from "react";

import "./portfolio-item.scss";

const PortfolioItem = ({ show, category, year, title, tools, activities, platforms, children, description }) => {
  if (!show) { return ""; }

  const yearText = Array.isArray(year) ? `${Math.min(...year)}–${Math.max(...year)}` : year;

  return <section className="portfolio-item">
    <figure className="teaser">
      {children}
    </figure>
    <div className="content">
      <header>
        <h1>
          <span className="year">{yearText}</span>
          <span className="title">{title}</span>
        </h1>
        <div className="category">
          <span>{category}</span>
        </div>
        <div className="tools">
          {tools.map(tool => <span className="tool" key={tool}>{tool}</span>)}
        </div>
      </header>
      <div className="description">
        {description}
      </div>
    </div>
  </section>
};

PortfolioItem.propTypes = {
  show: PropTypes.bool,
  category: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  title: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  tools: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
}

PortfolioItem.defaultProps = {
  show: true,
  category: "",
}

export default PortfolioItem
