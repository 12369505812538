import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PortfolioItem from "../components/portfolio-item";


const staticBaseURI = "//static.santala.dev/portfolio";

const scatterplot = staticBaseURI + "/videos/scatterplot.mp4";
const scatterplotPaper = staticBaseURI + "/files/santala_chi20src.pdf";
// TODO design mentor
const gps = staticBaseURI + "/images/gps.jpg";
// TODO intelligent layouts
const motionControl = staticBaseURI + "/videos/accelerometer-control.mp4";
const hangout1 = staticBaseURI + "/images/hangout-1.png";
const hangout2 = staticBaseURI + "/images/hangout-2.jpg";
const wasteSorting = staticBaseURI + "/images/waste-sorting.png";
const cabinet = staticBaseURI + "/images/cabinet.jpg";
const raytracing = staticBaseURI + "/images/raytracing.png";
const osakehait = staticBaseURI + "/videos/osakehait.mp4";
const scheduling1 = staticBaseURI + "/images/home.png";
const scheduling2 = staticBaseURI + "/images/new-meeting.png";
const scheduling3 = staticBaseURI + "/images/planning-phase.png";
const kch = staticBaseURI + "/images/kch.png";
const laserGame = staticBaseURI + "/videos/vuorovaikutustekniikan-studio.mp4";
const vrOnni = staticBaseURI + "/images/vr-onni.png";
const aarreaitta = staticBaseURI + "/images/aarreaitta.png";
const adventureGame = staticBaseURI + "/videos/2d-game.mp4";
const startti1a = staticBaseURI + "/images/startti1a.png";
const startti1b = staticBaseURI + "/images/startti1b.png";
const startti2a = staticBaseURI + "/images/startti2a.png";
const startti2b = staticBaseURI + "/images/startti2b.png";
const startti3a = staticBaseURI + "/images/startti3a.png";
const startti3b = staticBaseURI + "/images/startti3b.png";
const popo1 = staticBaseURI + "/images/popo-1.png";
const popo2 = staticBaseURI + "/images/popo-2.png";
const halu = staticBaseURI + "/images/halikon-lukio.png";

const categories = {
    design: "Design",
    programming: "Programming"
};
const both = [categories.design, categories.programming];

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCategoryActive: Object.fromEntries(Object.values(categories).map(c => [c, true]))
        }
    }
    getCategoryCheckBoxEventHandler = (category) => (
      ({ target: { checked }}) => {
          this.setState({ isCategoryActive: { ...this.state.isCategoryActive, [category]: checked } });
      }
    );
    showingAnyOf = (...categories) => categories.some(c => this.state.isCategoryActive[c]);
    render() {
        const { isCategoryActive } = this.state;
        const activeCategoryLabels = Object.entries(categories).filter(([categoryId, categoryLabel]) => (
          this.state.isCategoryActive[categoryId]
        )).map(([categoryId, categoryLabel]) => categoryLabel);
        return <Layout>
            <SEO title="Portfolio"/>
            <div className="portfolio-header">
                <h2>Portfolio</h2>
                <nav className="categories">
                    <ul>
                        {Object.values(categories).map(category => (
                          <li key={category}
                              className={`category-selector ${isCategoryActive[category] ? "active" : ""}`}>
                              <label>
                                  <input type="checkbox"
                                         checked={isCategoryActive[category]}
                                         onChange={this.getCategoryCheckBoxEventHandler(category)} />
                                  {category}
                              </label>
                          </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2020}
                           title="WIP: Automatic Scatterplot Design"
                           activities={["Computational Design", "Software Optimisation"]}
                           tools={["OpenCV", "Python", "JavaScript"]}
                           description={<>
                                <p>I am currently working on an interactive tool for designing scatterplots for large
                                    data sets (i.e. with millions of points). My solution to scatterplot rendering allows
                                    for rapid updates to the scatterplot design, regardless of the data set size.</p>
                                <p>Part of this project has been accepted to the <a href="https://chi2020.acm.org/authors/student-research-competition/">CHI’20 Student Research Competition.</a></p>
                           </>}>
                <VideoWithCaption src={scatterplot}>
                    Prototype visualisation tool implemented with OpenCV.js.
                </VideoWithCaption>
            </PortfolioItem>
            {""/*<PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={[2019, 2020]}
                           title="WIP Master’s Thesis: Sketch Plugin for Layout Optimisation"
                           activities={["Computational Design", "IX Design"]}
                           tools={["Integer Programming", "Python", "React.js", "Node.js", "Sketch API"]}>
            </PortfolioItem>*/}
            <PortfolioItem show={isCategoryActive[categories.programming]}
                           category={categories.programming}
                           year={2019}
                           title="WIP: GPS Tracker with Offline Maps"
                           activities={["Circuit Design"]}
                           tools={["Arduino", "C", "Python"]}>
                <ImageWithCaption src={gps}>
                    Prototype GPS tracker built around ATmega328P.
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2019}
                           title="Adaptive Motion Control"
                           activities={["Computational Design", "UI Development"]}
                           tools={["Accelerometer", "Processing", "Java"]}
                           description={
                             <p>In my Advanced Topics in User Interfaces class, I implemented an adaptive one-dimensional
                               accelerometer control scheme that optimises controller sensitivity according to the user skill.</p>
                           }>
                <VideoWithCaption src={motionControl}>
                    A test game to compare using accelerometer to control target position vs. speed.
                </VideoWithCaption>
            </PortfolioItem>
            {""/*<PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2019}
                           title="Intelligent UI Design Tool"
                           activities={["Computational Design", "UI Development"]}
                           tools={["Genetic Algorithms", "JavaScript"]}>
            </PortfolioItem>*/}
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2019}
                           title="Social Notification App Concept"
                           activities={["IX Design"]}
                           tools={["Figma", "JavaScript"]} description={
                               <p>In my IX design class, I collaborated with <a href="https://zhiwenyap.myportfolio.com/hangout">
                                   Zhiwen Yap</a> to design, prototype, and evaluate a concept for an application that
                                   helps close friends stay in touch effortlessly.</p>
            }>
                <ImageTableWithCaption sources={[[hangout1, hangout2]]}>
                    Figma prototype of a social notification app.
                </ImageTableWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={2018}
                           title="Waste Sorting Symbols"
                           activities={["Visual Design"]}
                           tools={["Sketch"]} description={<>
                               <p>In my USchool intro class, where we considered methods to
                                 facilitate trash sorting, I designed this set of icons as an exercise.
                                 You can read more about the project at the <a href="http://lauramk.me/portfolio/trash.html">website of Laura Meskanen-Kundu</a>.</p>
            </>}>
                <ImageWithCaption src={wasteSorting}>
                    I aimed to design icons with simple but recognizable shapes that could be used both in product
                  packaging as well as trash bins.
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={2018}
                           title="Cabinet Build"
                           activities={["Furniture Design", "Woodworking"]}
                           tools={["Glulam", "Plywood", "Circular Saw"]}
                           description={
                               <p>Learning woodworking skills from YouTube, I’ve designed and built a set of three
                                 cabinets from scratch, i.e. from full-sized plywood sheets and glulam boards that I
                                 cut to size myself using a hand-held circular saw.</p>
                           }>
                <ImageWithCaption src={cabinet}>
                    One of the three cabinets I designed and built from scratch without much prior experience in woodworking.
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.programming]}
                           category={categories.programming}
                           year={2018}
                           title="CPU Ray Tracer"
                           activities={["Software Optimisation"]}
                           tools={["C++", "GLSL"]}
                           description={
                               <p>I’ve completed both Computer Graphics courses of <a href="https://users.aalto.fi/~lehtinj7/">
                                   Jaakko Lehtinen</a>. In the 1<sup>st</sup> round of the
                                   advanced course, I managed to write the 2<sup>nd</sup> or 3<sup>rd</sup> fastest CPU
                                   ray tracer among the class.</p>
                           }>
                <ImageWithCaption src={raytracing}>
                    In my ray tracer, I implemented a physically accurate depth-of-field effect.
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2017}
                           title="Investment App Prototype"
                           activities={["UI Development"]}
                           tools={["Ionic 3", "Angular 2", "TypeScript"]}
                           description={
                               <p>In the SCI Project Course, our team developed a concept for a mobile investment
                                 app. <a href="https://www.linkedin.com/in/eskovahamaki">Esko Vähämäki</a> implemented
                                 the back end for our prototype, while I designed the GUI and implemented the front end.</p>
                           }>
                <VideoWithCaption src={osakehait}>
                    A very high-fidelity prototype of our investment app concept.
                </VideoWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={2017}
                           title="Scheduling App Concept"
                           activities={["IX Design"]}
                           tools={["Sketch"]}
                           description={
                                <p>When interviewing for a design job a few years back, I was asked to design an application for arranging meetings.
                                    These are some of the screens for the concept I created.</p>
                            }>
                <ImageTableWithCaption sources={[[scheduling1, scheduling2, scheduling3]]}>
                    My concept tries to solve scheduling problems for people who don’t share the same calendars.
                </ImageTableWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={2016}
                           title="Information Architecture for Kämp Collection Hotels"
                           activities={["Visual Design", "User Research"]}
                           tools={["Sketch"]}
                           description={
                               <p>In my Production Economics Studio class, I designed a new Information Architecture
                               and visual concept for Kämp Collection Hotels website.</p>
                           }>
                <ImageWithCaption src={kch}>
                    The conceptual Information Architecture is designed to support both B2C (page header) as
                    well as B2B audiences (page footer).
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2016}
                           title="Laser Shoot’em Up"
                           activities={["UI Development"]}
                           tools={["Processing", "Java", "WebCam", "Projector", "Laser Pointer", "Wireless Mouse"]}
                           description={
                               <p>In my Interaction Technique Studio class our group developed a Doom-inspired shooting game.
                                 I designed and implemented the laser-controlled interaction scheme using house-hold media equipment.</p>
                           }>
                <VideoWithCaption src={laserGame}>
                    Our game garnered excitement at the guild room. (Unmute the video to hear player reactions.)
                </VideoWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.programming]}
                           category={categories.programming}
                           year={2016}
                           title="Public Transport Network Visualisation"
                           activities={["Data Visualisation"]}
                           tools={["JavaScript", "PHP"]}
                           description={
                               <p>In my Basics of Networks class, I scraped OnniBus website for bus stop coordinates
                               and bus timetables, and utilised the open API for railway traffic to visualise public transport
                               connections in Finland.</p>
                           }>
                <ImageWithCaption src={vrOnni}>
                    Cyan: Train connections, Red: OnniBus connections, Yellow: shared connections.
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.programming]}
                           category={categories.programming}
                           year={2016}
                           title="Aarreaitta.net WordPress Theme"
                           activities={["UI Development"]}
                           tools={["JavaScript", "PHP", "WordPress"]}
                           description={
                               <p>I consulted on the design and implemented the WordPress theme for Aarreaitta.
                                   The visual identity is designed by <a href="http://saarasantala.fi">Saara Vähäkangas.</a></p>
                           }>
                <ImageWithCaption src={aarreaitta}>
                    <a href="//aarreaitta.net">aarreaitta.net</a>
                </ImageWithCaption>
            </PortfolioItem>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2015}
                           title="2D Adventure Game"
                           activities={["UI Development"]}
                           tools={["JavaScript Canvas", "Inkscape"]}
                           description={
                               <p>In the Basics of Web Publishing course, our group developed a 2D adventure  game. I was responsible for rendering and vehicle animation.
                                   I also developed a tool for mapping roads as a directed graph that was used for a vehicle way-finding algorithm
                                   as well as vehicle animation.</p>
                           }>
                <VideoWithCaption src={adventureGame}>
                    I implemented rendering and traffic animation for our game, as well as designed the world graphics.
                </VideoWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={[2013, 2014, 2015]}
                           title="Event Brochures"
                           activities={["UI Design"]}
                           tools={["Inkscape"]}
                           description={
                               <p>On three consecutive years, I designed three distinct visual identities for the same
                               event. Each year, I came up with a new visual style and new ways to present the same information.</p>
                           }>
                <ImageTableWithCaption sources={[[startti1a, startti1b], [startti2a, startti2b], [startti3a, startti3b]]}>
                    After the first year’s design, I convinced the client to invest into full-colour brochures.
                </ImageTableWithCaption>
            </PortfolioItem>
            <PortfolioItem show={this.showingAnyOf(...both)}
                           category={both.join("×")}
                           year={2012}
                           title="Responsive WordPress Theme"
                           activities={["Visual Design"]}
                           tools={["PHP", "WordPress"]}
                           description={
                               <p>My first paid gig as a web designer was for the scout troop Pöytyän Pöllöt.</p>
                           }>
                <ImageTableWithCaption sources={[[popo1, popo2]]}>
                    The <a href="http://web.archive.org/web/20190108093340/http://poytyanpollot.net/">former official website</a> of the Pöytyän Pöllöt scout troop.
                </ImageTableWithCaption>
            </PortfolioItem>
            <PortfolioItem show={isCategoryActive[categories.design]}
                           category={categories.design}
                           year={2010}
                           title="Halikon Lukio Logo"
                           activities={["Visual Design"]}
                           tools={["Inkscape"]}
                           description={
                               <p>My first paid graphic design gig was the logo design for Halikon lukio.
                               I went through a few revisions always simplifying the logo a bit more. The final
                               design has stood the test of time well.</p>
                           }>
                <ImageWithCaption src={halu}>
                    The official logo of <a href="https://peda.net/salo/koulut/halikon-lukio">Halikon lukio</a>
                </ImageWithCaption>
            </PortfolioItem>
        </Layout>
    }
}

function ImageWithCaption({ src, children }) {
    return <>
        <img src={src} />
        <figcaption>{children}</figcaption>
    </>
}

function ImageTableWithCaption({ sources, children }) {
    return <>
        <div className="table">
            {sources.map(row =>
                <div className="row">
                    {row.map(src =>
                        <div className="col"><img src={src} /></div>
                    )}
                </div>
            )}
        </div>
        <figcaption>{children}</figcaption>
    </>
}

function VideoWithCaption({ src, children, muted }) {
    muted = muted || true;
    return <>
        <video src={src} controls muted={muted} />
        <figcaption>{children}</figcaption>
    </>
}

export default IndexPage
